import validator from 'validator';

export default {
  required(msg) {
    return value => !validator.isEmpty(value ? value.toString() : '') || msg;
  },
  isEmail(msg) {
    return value => validator.isEmail(value) || msg;
  },
  min(length, msg) {
    return value => value.length >= length || msg;
  },
  max(length, msg) {
    return value => value.length <= length || msg;
  },
  minMaxValue(min, max, msg) {
    return value => (value >= min && value <= max) || msg;
  },
  equal(prop, msg) {
    return value => validator.equals(value, prop) || msg;
  },
  minMaxArrayLength(min, max, msg) {
    return value => (value.length >= min && value.length <= max) || msg;
  },
  isUrl(msg) {
    return value => {
      if (!value) return true;

      return (value.includes('http') && validator.isURL(value)) || msg;
    };
  }
};
